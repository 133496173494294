
.App {
    margin: 10px;
}

  
.Login .input-group {
    width: 40% !important;
  }
  

  
