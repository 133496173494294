button {
    margin-right: 15px;
    border-radius: 0;
    border: 1px solid #1A0031;
    background-color: #FFF;
    color: #1A0031;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 15px;
    cursor: pointer;
}
button:hover {
    color: #fff;
    background-color: #592b88;
}
button.active {
    cursor: normal;
    background-color: #1A0031;
    color: #fff;
}
button.active:hover {
    cursor: normal !important;
}

.extras{
    display:flex;
    margin-top: 25px;
}
select {
    padding-left: 8px;
    font-size: 16px;
    font-weight: bold;
    width: 175%;
    height: 40px;
    border-radius: 5px 5px;
    border-color: rgb(211, 211, 211);
}
.singleElementContainer button {
    margin-top: 15px;
    margin-bottom: 15px;
}
.singleElementContainer div {
    margin-bottom: 10px;
}
.singleElementContainer label {
    font-weight: bold;
    min-width: 250px;
    display: inline-block;
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;    
}

.dropdown {
    display: inline-block;
}
a {
    color: #1a0031;
}
.dropdown-toggle {
    border-color: #1a0031;
}
.editButton:hover {
    color: #fff;
    background-color: #592b88;
    border-color: #1A0031;
}
.editButton {
    cursor: normal;
    border-radius: 5px;
    background-color: #1A0031;
    border-color: #1A0031;
    color: #fff;
}

.tabButton {
    border-radius: 5px 5px 0px 0px;
    height: 50px;
    border-bottom: 10px;
}
.innerScroll {
    overflow-y: scroll;
    max-height: 40vh;
}

.spinnerStyles {
    position: absolute;
    top: 50%;
    left: 50%;
}

.dateStyle{
    padding-left: 8px;
    height: 40px;
    border-radius: 5px 5px;
    border-color: rgb(211, 211, 211);
}
.formLayout {
    margin-left: 30px;
    margin-top: 20px
}
h2 {
    margin-left: 30px;
    margin-top: 20px;
    color: rgb(28, 119, 255);
    font-weight:lighter;
}
.loginText {
    margin-left: 0px;
    margin-top: 20px;
    color: rgb(28, 119, 255);
    font-weight:lighter;
}
hr {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}

.Home .form-inline {
    width: 100%;
  }
  
.Home .form-group {
    width: 50%;
  }
  
.Home .input-group {
    width: 50% !important;
  }
  
.Home .form-control {
    width: 50% !important;
  }

.Login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
  }


  @media screen and (max-width: 960px) {
    .Home .form-inline {
        width: 100%;
      }
      
    .Home .form-group {
        width: 100%;
      }
      
    .Home .input-group {
        width: 100% !important;
      }
      
    .Home .form-control {
        width: 100% !important;
      }
 
  }